<template>
  <div>
    <b-button v-b-modal.createQuestModal v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="gradient-success" block>
      สร้าง
    </b-button>

    <b-modal v-model="modal" size="lg" id="createQuestModal" title="สร้าง">
      <div class="row">
        <div class="col-8">
          <b-form-group label="เวลาหมด:" label-for="close_at">
            <flat-pickr id="close_at" v-model="form.close_at" :config="closeAtConfig" class="form-control" />
          </b-form-group>
        </div>
      </div>

      <div class="row" v-for="(game, index) in form.games">
        <div class="col-4">
          <b-form-group :label="`ค่าย`" label-for="game_productId">
            <b-form-select v-model="game.productId" :options="productOptions" text-field="productName"
              value-field="id"></b-form-select>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group :label="`เกม`" label-for="game_id">
            <b-form-select v-model="game.game_id" :options="gameOptions" text-field="name"
              value-field="id"></b-form-select>
          </b-form-group>
        </div>
        <div class="col-2">
          <b-form-group label="%ต่ำสุด" label-for="min_percent">
            <b-form-input v-model="game.min_percent" type="number" required></b-form-input>
          </b-form-group>
        </div>
        <div class="col-2">
          <b-form-group label="%สูงสุด" label-for="max_percent">
            <b-form-input v-model="game.max_percent" type="number" required></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-end">
        <b-button @click="addGame" size="sm" variant="info">เพิ่มเกม</b-button>
      </div>

      <template #modal-footer>
        <b-button variant="success" @click="submit()">
          ยืนยัน
        </b-button>
        <b-button variant="danger" @click="cancel()">
          ยกเลิก
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BTab, BFormRadio, BTabs, BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment-timezone';
import 'flatpickr/dist/flatpickr.css';

export default {
  components: {
    BTab, BFormRadio, BTabs, BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
    flatPickr
  },
  directives: {
    Ripple
  },
  props: ['gameOptions', 'productOptions', 'games'],
  emits: ['submit'],
  data: () => ({
    modal: false,
    form: {
      close_at: null,
      games: [
        { productId: 16, game_id: '' }
      ]
    },
    closeAtConfig: {
      enableTime: true,
      time_24hr: true,
    },
  }),
  methods: {
    submit() {
      this.$emit('submit', this.form)
      this.modal = false
      this.clearForm();
    },
    addGame() {
      this.form.games.push({ productId: 16, game_id: '' })
    },
    productChange(e) {
      this.$emit('getGames', e)
    },
    cancel() {
      this.modal = false;
      this.clearForm();
    },
    clearForm() {
      this.form = {
        close_at: null,
        games: [
          { productId: 16, game_id: '' }
        ]
      }
    }
  }
}
</script>